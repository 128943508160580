






























import { Component, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import AppLoading from "@/components/core/AppLoading.vue";
import TheVerticalCenter from "@/components/TheVerticalCenter.vue";
import { joinCatalog } from "@/utils/api";

@Component({ components: { AppButton, AppLoading, TheVerticalCenter } })
export default class AppCatalogCodeModal extends Vue {
  error = false;
  saving = false;

  get accessCode(): string {
    return this.$route.params.accessCode;
  }

  created() {
    this.joinViaCode();
  }

  async joinViaCode(): Promise<void> {
    this.saving = true;
    try {
      await joinCatalog({ accessCode: this.accessCode });
      this.$router.push({ name: "HOME" });
    } catch (err) {
      this.error = true;
    } finally {
      this.saving = false;
    }
  }

  goHome(): void {
    this.$router.push({ name: "HOME" });
  }
}
